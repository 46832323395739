<template>
  <b-container fluid="sm">
    <b-row v-if="item">
      <b-col
        sm="12"
        class="imageContainer"
        :style="`background-image: url(${item.imagen})`"
      ></b-col>
      <b-col sm="12" class="mb-0 mt-5">
        <h1 class="p-mb-0 titulo">{{ item.titulo.toUpperCase() }}</h1>
      </b-col>
      <b-col sm="12" class="mb-0">
        <p class="autores">
          {{this.firstLetterToUpperCase($t("messages.authors"))}}: {{ this.allFirstLettersUpperCase(item.autor) }} / {{this.firstLetterToUpperCase($t("messages.published"))}}:
          {{ formatDate(item.fecha_publicacion) }}
        </p>
      </b-col>
      <hr class="divisor" />
      <b-col sm="12" class="ql-editor">
        <p class="cuerpo"><span v-html="item.cuerpo"></span></p>
      </b-col>
      <b-col sm="12" class="mt-5 mb-5">
        <p class="referencias">
          <pre class="pre-references">{{ $t("messages.references") }}</pre>
          <pre class="pre-references">{{ item.referencias }}</pre>
        </p>        
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ArticulosService from "@/services/articulos.service";
import { Helpers } from "@/mixins/Helpers";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "articulos",
  mixins: [Helpers],
  data() {
    return {
      articulosService: null,
      item: null,
      loading: false,
      id: 0,
    };
  },
  created() {
    this.articulosService = new ArticulosService();
    this.id = this.$route.params.id;
  },
  mounted() {
    this.getArticulo();
  },
  methods: {
    getArticulo() {
      this.loading = true;
      this.articulosService
        .getArticuloById(this.id)
        .then((result) => {
          if (result.data.data.length == 0) {
            this.item = null;
            this.$router.push({ name: "main" });
          } else {
            this.item = { ...result.data.data };
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar el artículo",
          });
          this.loading = false;
        });
    },
    formatDate(fecha) {
      return new Date(fecha).toLocaleDateString("en-GB");
    },
  },
  watch: {
    $route(to) {
      this.id = this.$route.params.id;
      this.getArticulo();
    },
  },
};
</script>

<style scoped>

.pre-references {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 15px;
  margin: 0px;
}
.divisor {
  border: 1px solid var(--primary-misty-oracle);
  margin: 0px;
  width: 100%;
  margin-bottom: 20px;
}
.container {
  padding: 20px 300px;
  min-height: 600px;
}
.titulo {
  font-weight: bold;
  color: #205fa1;
}
.autores {
  font-weight: 700;
}
.cuerpo {
  text-align: justify;
  margin: 0px;
  font-size: 22px;
}
.referencias {
  text-align: justify;
  margin: 0px;
  font-size: 15px;
}
.imageContainer {
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
@media screen and (max-width: 1450px) {
  .container {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 800px) {
  .imageContainer {
    height: 150px;
    width: 100%;
    background-size: cover;
  }
  .titulo {
    font-size: 20px;
  }
  .cuerpo {
    text-align: justify;
    margin: 0px;
    font-size: 15px;
  }
  .container {
    padding: 10px 10px;
  }
}
</style>
